import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/clike/clike";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/python/python";

const problemStatement =
"Write a program to perform bubble sort in a language of your choice";

const LoadingSpinner = () => (
  <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-100 bg-opacity-75 z-50">
    <p className="mb-4 text-lg font-semibold text-gray-700">
      Processing your Code. Please do not go back!
    </p>
    <div className="flex space-x-2">
      <div className="w-5 h-5 bg-blue-500 rounded-full animate-bounce"></div>
      <div className="w-5 h-5 bg-blue-500 rounded-full animate-bounce delay-200"></div>
      <div className="w-5 h-5 bg-blue-500 rounded-full animate-bounce delay-400"></div>
    </div>
  </div>
);

const CodeSubmissionPage = ({ airtableCredentials, userData }) => {
  const [code, setCode] = useState("");
  const [score, setScore] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [feedback, setFeedback] = useState("");
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/dashboard");
  };

  const extractStudentCode = (email) => {
    const pattern =
      /^(\w{3})(\w{3})(\d{2})(\d{3})@brainwareuniversity\.ac\.in$/;
    const match = email.match(pattern);
    if (match) {
      const [_, prefix, dept, year, number] = match;
      const studentCode = `BWU/${dept.toUpperCase()}/${year}/${number}`;
      return studentCode;
    }
    console.log("Failed to extract Student Code from email:", email);
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setFeedback("");

    try {
      // Generate code score and feedback from OpenAI
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: "You are an expert in code quality assessment.",
            },
            {
              role: "user",
              content: `Problem Statement: ${problemStatement}\n\nCode:\n${code}\n\nPlease score this code on a scale from 0 to 100 based on the following criteria: functionality, readability, structure, efficiency, and adherence to best practices. Provide a detailed explanation for each criterion. Be strict with bad code, and reward high scores up to 100 for good code. At the end, always include the overall score in the format: Overall Score: [score]`,
            },
          ],
          max_tokens: 1000,
          temperature: 0.5,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      const feedbackResponse = response.data.choices[0].message.content.trim();
      const overallScoreMatch = feedbackResponse.match(
        /Overall Score: (\d{1,3})/
      );
      const overallScore = overallScoreMatch
        ? parseInt(overallScoreMatch[1], 10)
        : 0;

      setScore(overallScore);
      setFeedback(feedbackResponse);

      // Extract student code from email
      const studentCode = extractStudentCode(userData.email);

      // Save code information to Airtable
      await axios.post(
        `https://api.airtable.com/v0/${airtableCredentials.baseId}/tblyjntdTistjS2UM`,
        {
          fields: {
            email: userData.email,
            name: userData.name,
            studentCode: studentCode,
            projectTitle: problemStatement,
            score: overallScore,
            status: "Submitted, Reviewed by AI",
            code: code,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${airtableCredentials.apiKey}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      setError("Failed to submit the code. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const formatFeedback = (feedback) => {
    const formattedFeedback = feedback.replace(
      /(Functionality|Readability|Structure|Efficiency|Adherence to Best Practices|Overall Score):/g,
      (match) => `**${match}**`
    );

    const sections = formattedFeedback
      .split("\n")
      .filter((section) => section.trim() !== "");

    return sections.map((section, index) => (
      <p key={index} className="mb-2">
        <span
          dangerouslySetInnerHTML={{
            __html: section.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>"),
          }}
        />
      </p>
    ));
  };

  return (
    <div className="container mx-auto px-0 py-8">
      {loading && <LoadingSpinner />}
      <button
        onClick={handleGoBack}
        className="bg-white hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded inline-flex items-center mb-4"
      >
        <i className="fas fa-arrow-left mr-2"></i> Go Back
      </button>
      <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-black md:text-5xl lg:text-6xl text-center">
        Submit Your Code 💭
      </h1>
      <h2 className="text-1xl font-semibold my-4 text-center text-gray-700">
        The tool accepts any programming language of your choice (Java, C++, Python, etc). Write according to problem statement ⬇️
      </h2>
      <div className="text-lg font-semibold text-center mb-4">
        <p className="bg-yellow-200 text-black mt-4 px-4 py-2 rounded-md shadow-md border border-yellow-500 inline-block">
          {problemStatement}
        </p>
      </div>

      <form
        onSubmit={handleSubmit}
        className="w-full lg:w-4/5 xl:w-3/4 mx-auto bg-white shadow-md rounded-md space-y-4"
      >
        <CodeMirror
          value={code}
          options={{
            mode: "javascript",
            theme: "material",
            lineNumbers: true,
            readOnly: score !== null,
          }}
          onBeforeChange={(editor, data, value) => {
            setCode(value);
          }}
          className="w-full h-full"
        />
        <div className="mt-4">
          <button
            type="submit"
            className={`w-full text-white font-bold py-3 px-4 rounded ${
              loading || score !== null
                ? "bg-green-300 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600"
            }`}
            disabled={loading || score !== null}
          >
            {loading
              ? "Submitting..."
              : score !== null
              ? "Submitted"
              : "Submit"}
          </button>
        </div>
      </form>
      {score !== null && (
        <div className="mt-6 text-center">
          <h2 className="text-2xl font-bold mt-8 mb-4 text-gray-800 border-b-2 border-gray-300 pb-2">
            Your Code's Score: {score}/100
          </h2>
          <div className="mt-4 bg-gray-100 p-4 rounded-md text-left">
            {formatFeedback(feedback)}
          </div>
        </div>
      )}

      {error && (
        <div className="mt-6 text-center text-red-500">
          <p>{error}</p>
        </div>
      )}
      <footer className="mt-8 text-center text-gray-500">
        <p>Built with ❤️ by CodeSmarters</p>
      </footer>
    </div>
  );
};

export default CodeSubmissionPage;
