import React from "react";

const Header = () => {
  return (
    <header>
      <nav className="bg-gray-800 border-b border-gray-800 px-4 lg:px-6 py-6">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <a href="/" className="flex items-center">
            <img
              src={require("../components/images/logo.jpg")}
              className="mr-3 h-12"
              alt="BWU Logo"
            />
            <span className="self-center text-xl font-semibold whitespace-nowrap text-white hidden lg:flex">
              Computer Science & Engineering
            </span>
          </a>
          <div className="flex items-center lg:order-2">
            <a
              href="/login"
              className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 focus:outline-none"            >
              Get Started
            </a>
            {/* <a
              href="/register"
              className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 focus:outline-none"
            >
              Get started
            </a> */}
          </div>
          <div
            className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
            id="mobile-menu-2"
          >
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
              <li>
                <a
                  href="/"
                  className="block py-2 pr-4 pl-3 text-gray-300 border-b border-gray-800 hover:bg-gray-800 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-500 lg:p-0"
                  aria-current="page"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/competition"
                  className="block py-2 pr-4 pl-3 text-gray-300 border-b border-gray-800 hover:bg-gray-800 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-500 lg:p-0"
                >
                  Competition
                </a>
              </li>
              <li>
                <a
                  href="/talent"
                  className="block py-2 pr-4 pl-3 text-gray-300 border-b border-gray-800 hover:bg-gray-800 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-500 lg:p-0"
                >
                  Talents
                </a>
              </li>
              <li>
                <a
                  href="/contact-us"
                  className="block py-2 pr-4 pl-3 text-gray-300 border-b border-gray-800 hover:bg-gray-800 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-500 lg:p-0"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;