import React from 'react';

const MobileBanner = () => {
  return (
    <div className="bg-indigo-600 md:hidden"> {/* hidden on medium and larger screens */}
      <div className="max-w-screen-xl mx-auto px-4 py-3 text-white sm:text-center md:px-8">
        <p className="font-medium">
          🔔 To experience the full functionality of our site, visit on a Desktop!
        </p>
      </div>
    </div>
  );
}

export default MobileBanner;
