import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EditProfileForm from "../components/EditProfileForm";
import axios from "axios";
import "./styles/Dashboard.css";

const LoadingSpinner = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-100 bg-opacity-75 z-50">
    <div className="text-center flex items-center space-x-4">
      <svg
        className="animate-spin h-5 w-5 text-blue-500"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zM2 12a10 10 0 0110-10V0C4.477 0 0 4.477 0 12h2z"
        ></path>
      </svg>
      <p className="text-lg font-semibold text-gray-700">Loading...</p>
    </div>
  </div>
);

const Dashboard = ({ onLogout, userData, recordId, airtableCredentials }) => {
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [localUserData, setLocalUserData] = useState(userData);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("dashboard");
    return () => {
      document.body.classList.remove("dashboard");
    };
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      const projectsData = await fetchUserProjects(localUserData.email);
      // Sort projects by createdTime in descending order
      projectsData.sort(
        (a, b) => new Date(b.createdTime) - new Date(a.createdTime)
      );
      setProjects(projectsData);
      setLoading(false);
    };

    fetchProjects();
  }, [localUserData.email]);

  const fetchUserData = async () => {
    try {
      const url = `https://api.airtable.com/v0/${airtableCredentials.baseId}/tblF5La1RkLC3gXsi/${recordId}`;
      const config = {
        headers: {
          Authorization: `Bearer ${airtableCredentials.apiKey}`,
        },
      };
      const response = await axios.get(url, config);
      return response.data.fields;
    } catch (error) {
      console.error("There was an error fetching the user data:", error);
    }
  };

  const fetchUserProjects = async (email) => {
    try {
      const url = `https://api.airtable.com/v0/${airtableCredentials.baseId}/tblyjntdTistjS2UM?filterByFormula={email}="${email}"`;
      const config = {
        headers: {
          Authorization: `Bearer ${airtableCredentials.apiKey}`,
        },
      };
      const response = await axios.get(url, config);
      return response.data.records.map((record) => ({
        projectTitle: record.fields.projectTitle,
        score: record.fields.score,
        status: record.fields.status,
        code: record.fields.code,
        createdTime: record.createdTime,
      }));
    } catch (error) {
      console.error("There was an error fetching the user projects:", error);
    }
  };

  const handleEditProfile = () => {
    setShowEditProfile(true);
  };

  const handleClose = () => {
    setShowEditProfile(false);
  };

  const handleSave = async (updatedData) => {
    try {
      const url = `https://api.airtable.com/v0/${airtableCredentials.baseId}/tblF5La1RkLC3gXsi/${recordId}`;
      const config = {
        headers: {
          Authorization: `Bearer ${airtableCredentials.apiKey}`,
        },
      };
      const data = {
        fields: updatedData,
      };
      await axios.patch(url, data, config);
      const latestUserData = await fetchUserData();
      setLocalUserData(latestUserData);
      localStorage.setItem("userData", JSON.stringify(latestUserData));

      setShowEditProfile(false);
    } catch (error) {
      console.error("There was an error updating the record:", error);
    }
  };

  const handleCreateNewProject = () => {
    navigate("/submit-code");
  };

  const getCurrentProject = () => {
    if (projects.length === 0) {
      return {
        projectTitle: "No Code submitted yet",
        projectStatus: "N/A",
      };
    }
    const currentProject = projects[0]; // Latest project after sorting
    return {
      projectTitle: currentProject.projectTitle,
      projectStatus: currentProject.status,
    };
  };

  const { projectTitle, projectStatus } = getCurrentProject();

  const [expandedCodes, setExpandedCodes] = useState({});

  const toggleCodeView = (index) => {
    setExpandedCodes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {loading && <LoadingSpinner />}
      <h1 className="mb-8 text-2xl font-semibold leading-tight tracking-tight text-blue-800 md:text-3xl lg:text-4xl text-center">
        Greetings, {localUserData.name}!
      </h1>

      <div className="flex flex-col lg:flex-row justify-around mb-8 space-y-8 lg:space-y-0 lg:space-x-4">
        {/* User Profile */}
        <div className="bg-slate-200 p-6 rounded-lg shadow-lg flex-1 flex flex-col">
          <h2 className="text-2xl font-semibold mb-4 text-center text-blue-800">
            User Profile 👋
          </h2>
          <p className="mb-2">
            <strong>Name:</strong> {localUserData.name}
          </p>
          <p className="mb-2">
            <strong>Email:</strong> {localUserData.email}
          </p>
          <p className="mb-2">
            <strong>Phone Number:</strong> {localUserData.phoneNumber}
          </p>
          <p className="mb-2">
            <strong>Section:</strong> {localUserData.section}
          </p>
          <div className="flex justify-center mt-auto">
            <button
              className="bg-blue-600 hover:bg-blue-800 text-white font-normal py-2 px-4 rounded"
              onClick={handleEditProfile}
            >
              Edit Profile
            </button>
          </div>
        </div>

        {/* Project Overview */}
        <div className="bg-slate-200 p-6 rounded-lg shadow-lg flex-1 flex flex-col">
          <h2 className="text-2xl font-semibold mb-4 text-center text-blue-800">
            Analyse your Code 🧑‍💻
          </h2>
          <p className="mb-2">
            <strong> 👀 Last Project:</strong> {projectTitle}
          </p>
          <p className="mb-2">
            <strong> 👉 Status:</strong> {projectStatus}
          </p>
          <div className="flex justify-center mt-auto">
            <button
              className="bg-blue-600 hover:bg-blue-800 text-white font-normal py-2 px-4 rounded"
              onClick={handleCreateNewProject}
            >
              Participate
            </button>
          </div>
        </div>
      </div>

      {/* User Projects */}
      <div className="bg-white p-6 rounded-lg ">
        <h2 className="text-2xl font-semibold mb-4 text-center text-blue-800">
          Your Submitted Projects ✅
        </h2>
        {loading ? (
          <LoadingSpinner />
        ) : projects.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead className="bg-slate-200">
                <tr>
                  <th className="py-2 px-4 border-b border-gray-200 text-left">
                    Project Title (Recent to Oldest)
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left">
                    Score
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left">
                    Status
                  </th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left">
                    Code
                  </th>
                </tr>
              </thead>
              <tbody>
                {projects.map((project, index) => (
                  <tr key={index} className="text-gray-700">
                    <td className="py-2 px-4 border-b border-gray-200">
                      {project.projectTitle}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200">
                      {project.score}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200">
                      {project.status}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-200">
                      <button
                        onClick={() => toggleCodeView(index)}
                        className="text-blue-600 hover:underline text-sm font-medium focus:outline-none"
                      >
                        {expandedCodes[index] ? "Hide Code" : "Show Code"}
                      </button>
                      {expandedCodes[index] && (
                        <pre className="text-sm mt-2 bg-gray-100 p-2 rounded">
                          {project.code}
                        </pre>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center text-gray-700">No Code submitted yet.</p>
        )}
      </div>

      <div className="w-full flex justify-center mt-8">
        <button
          onClick={onLogout}
          className="bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          Logout
        </button>
      </div>

      {showEditProfile && (
        <div className="overlay">
          <EditProfileForm
            onClose={handleClose}
            onSave={handleSave}
            userData={localUserData}
          />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
