import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

function LoginForm({ onLogin, showInvalidCredentials }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    document.body.classList.add("form-page");
    return () => {
      document.body.classList.remove("form-page");
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoggingIn(true);

    try {
      await onLogin(email, password);
    } catch (error) {
      console.error("Login failed:", error);
    }
    setIsLoggingIn(false);

    if (formRef.current) {
      formRef.current.reset();
    }
  };

  return (
    <section className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="flex flex-col items-center justify-center px-3 py-4 mx-auto md:h-screen lg:py-0">
        {/* Go Back Link */}
        <Link
          to="/"
          className="absolute top-12 left-10 text-gray-900 lg:inline-block"
        >
          <i className="fas fa-arrow-left mr-2"></i> Go Back
        </Link>
        <Link
          to="/"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900"
        >
          <img
            className="w-20 h-20 mr-2"
            src={require("./images/logo.jpg")}
            alt="logo"
          />
        </Link>
        <div className="w-full max-w-screen-md mx-auto bg-white rounded-lg shadow dark:border md:mt-0 xl:p-0">
          <div className="p-20 space-y-4 md:space-y-6 sm:p-20">
            <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900 md:text-3xl">
              Sign in to your account
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="student_code@brainwareuniversity.ac.in"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required
                />
              </div>

              {showInvalidCredentials && (
                <p className="text-red-500 text-sm font-semibold">
                  Invalid Credentials. Please try again.
                </p>
              )}

              <button
                type="submit"
                className={`w-full text-white ${
                  isLoggingIn
                    ? "bg-gray-500 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300"
                } font-medium rounded-lg text-sm px-5 py-2.5 text-center ${
                  isLoggingIn
                    ? "dark:bg-gray-500"
                    : "dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800"
                }`}
                disabled={isLoggingIn}
              >
                {isLoggingIn ? "Signing in..." : "Sign in"}
              </button>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Don’t have an account yet?{" "}
                <Link
                  to="/register"
                  className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  Sign up
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginForm;