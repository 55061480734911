import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";

function RegistrationForm({ onRegister, airtableCredentials }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [referrer, setReferrer] = useState("");
  const [section, setSection] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [preferredLanguage, setPreferredLanguage] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");

  const location = useLocation();
  const prefilledData = location.state?.prefilledData;

  // useEffect(() => {
  //   if (prefilledData) {
  //     setName(prefilledData.name);
  //     setPreferredLanguage(prefilledData.projectTitle);
  //     const email = convertStudentCodeToEmail(prefilledData.studentCode);
  //     setEmail(email);
  //   }
  // }, [prefilledData]);

  useEffect(() => {
    document.body.classList.add("form-page");
    return () => {
      document.body.classList.remove("form-page");
    };
  }, []);

  const convertStudentCodeToEmail = (studentCode) => {
    if (!studentCode) return "";
    const parts = studentCode.split("/").slice(1);

    return `bwu${parts.join("")}@brainwareuniversity.ac.in`.toLowerCase();
  };

  async function checkIfUserExists(email) {
    try {
      const response = await axios.get(
        `https://api.airtable.com/v0/${
          airtableCredentials.baseId
        }/tblF5La1RkLC3gXsi?filterByFormula={email}='${encodeURIComponent(
          email
        )}'`,
        {
          headers: {
            Authorization: `Bearer ${airtableCredentials.apiKey}`,
          },
        }
      );
      return response.data.records.length !== 0;
    } catch (error) {
      console.error("Error checking user:", error);
      console.error(
        "Error details:",
        error.response ? error.response.data : "No additional error details"
      );
      return false;
    }
  }

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@brainwareuniversity\.ac\.in$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords do not match.");
      setLoading(false);
      return;
    }

    if (!termsAccepted) {
      alert("You must agree to the terms and conditions.");
      setLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setEmailError(
        "Please enter a valid email address. Only emails ending with @brainwareuniversity.ac.in are allowed."
      );
      setLoading(false);
      return;
    }

    const phoneNumberPattern = /^[0-9]*$/;
    if (!phoneNumberPattern.test(phoneNumber)) {
      alert("Please enter a valid phone number.");
      setLoading(false);
      return;
    }

    const isEmailRegistered = await checkIfUserExists(email);

    if (isEmailRegistered) {
      setEmailExists(true);
      setLoading(false);
      return;
    }

    try {
      await onRegister(
        name,
        email,
        password,
        section,
        termsAccepted,
        phoneNumber
      );
      setRegistrationSuccess(true);
    } catch (error) {
      console.error("Registration failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        {/* Go Back Link */}
        <Link
          to="/login"
          className="absolute top-12 left-10 text-gray-900 hidden lg:inline-block"
        >
          <i className="fas fa-arrow-left mr-2"></i> Go Back
        </Link>
        {/* Logo and Header Text */}
        <Link
          to="/"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900"
        >
          <img
            className="w-20 h-20 mr-2"
            src={require("./images/logo.jpg")}
            alt="logo"
          />
          {/* TEXT CAN BE ADDED HERE */}
        </Link>

        {/* Registration Form Container */}
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8 form-container">
            <h2 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              Register for an account
            </h2>

            {!registrationSuccess ? (
              // Registration Form
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="auth-form-group">
                  <label cla>
                    What do we call you?<span className="required-star">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>

                <div className="auth-form-group">
                  <label>
                    University Email<span className="required-star">*</span>
                  </label>
                  <input
                    type="email"
                    placeholder="yourname@brainwareuniversity.ac.in"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  {emailError && (
                    <p className="auth-error-message">{emailError}</p>
                  )}
                  {emailExists && (
                    <p className="auth-error-message">
                      Email is already registered.
                    </p>
                  )}
                </div>

                <div className="auth-form-group">
                  <label>
                    Choose a Password<span className="required-star">*</span>
                  </label>
                  <input
                    type="password"
                    placeholder="••••••••"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>

                <div className="auth-form-group">
                  <label>
                    Confirm your Password
                    <span className="required-star">*</span>
                  </label>
                  <input
                    type="password"
                    placeholder="••••••••"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>

                {/* <div className="auth-form-group">
                  <label>
                    How did you hear about us?
                    <span className="required-star">*</span>
                  </label>
                  <select
                    onChange={(e) => setReferrer(e.target.value)}
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="Social Media">Social Media</option>
                    <option value="Friend or Family">Friend or Family</option>
                    <option value="Search Engine">Search Engine</option>
                    <option value="Educational Institution">
                      Educational Institution
                    </option>
                    <option value="Other">Other</option>
                  </select>
                </div> */}

                {/* <div className="auth-form-group">
                  <label>
                    Languages you plan using in your projects?
                    <span className="required-star">*</span>
                  </label>
                  <input
                    type="text"
                    name="preferredLanguage"
                    placeholder="eg, C++, Java, Python"
                    value={preferredLanguage}
                    onChange={(e) => setPreferredLanguage(e.target.value)}
                    required
                  />
                </div> */}

                <div className="auth-form-group">
                  <label>
                    Section<span className="required-star">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="BCSE-2020(A), etc."
                    value={section}
                    onChange={(e) => setSection(e.target.value)}
                    required
                  />
                </div>

                <div className="auth-form-group">
                  <label>
                    Phone Number (Max 10 Digits)
                    <span className="required-star">*</span>
                  </label>
                  <input
                    type="tel"
                    pattern="[0-9]*"
                    placeholder="eg, XXXXXXXXXX"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </div>

                {/* <div className="auth-form-group">
                  <label>
                    Where do you live right now?
                    <span className="required-star">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Kolkata, India."
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  />
                </div> */}

                <div className="auth-form-group">
                  <label className="flex items-center cursor-pointer">
                    <input
                      id="accept-terms"
                      type="checkbox"
                      className="form-checkbox text-blue-500"
                      checked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                      required
                    />
                    <span className="ml-2">
                      I agree to the{" "}
                      <a
                        href="https://docs.google.com/document/d/1pq0WBH4rkv6WGqhxWkPA-umMGdfPr3NEQXXLVZ7Di4I/edit?usp=sharing"
                        className="text-blue-500"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        terms and conditions
                      </a>
                      .
                    </span>
                  </label>
                </div>

                {/* Form Submit Button */}
                <button
                  type="submit"
                  className="w-full py-3 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Register"}
                </button>

                {/* Already have an account? Login Link */}
                <div className="text-center text-gray-500">
                  <span>Already have an account? </span>
                  <Link to="/login" className="text-blue-500">
                    Login here
                  </Link>
                </div>
              </form>
            ) : (
              // Registration Success Message
              <div className="space-y-4 md:space-y-6">
                <p className="text-lg font-semibold text-green-500">
                  Registration successful!
                </p>
                <p>
                  You can now{" "}
                  <Link to="/login" className="text-blue-500">
                    login here
                  </Link>{" "}
                  with your credentials.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default RegistrationForm;
