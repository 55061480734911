import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/Header";

const Talent = ({ airtableCredentials }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const url = `https://api.airtable.com/v0/${airtableCredentials.baseId}/tbl2jhuQaDCmrr7Cw`;
        const config = {
          headers: {
            Authorization: `Bearer ${airtableCredentials.apiKey}`,
          },
        };
        const response = await axios.get(url, config);
        setProjects(response.data.records.map((record) => record.fields));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setLoading(false);
      }
    };

    fetchProjects();
  }, [airtableCredentials]);

  return (
    <div>
      <Header />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-semibold my-6 text-center border-b-2 border-gray-300 pb-6">
          Browse Featured Projects
        </h1>

        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {projects.map((project, index) => (
              <div
                key={index}
                className="bg-white shadow-md border border-gray-300 rounded-md p-4"
              >
                <h2 className="text-lg font-semibold mb-2">
                  {project.projectTitle}
                </h2>
                <p className="text-gray-600 mb-2">
                  Submitted by: {project.name}
                </p>
                <p className="text-gray-600 mb-2">
                  Student Code: {project.studentCode}
                </p>
                <a
                  href={project.projectURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  Visit Project ↗️
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Talent;